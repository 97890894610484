import { Languages } from 'components/config/languages';

const fillDate = (minYear) => {
  const months = [...Array(12).keys()].map((key) =>
    new Date(0, key).toLocaleString('en', { month: 'long' }),
  );

  const today = new Date();
  const result = [
    {
      title: today.getFullYear(),
      items: months.slice(0, today.getMonth() + 1),
    },
  ];
  for (let year = today.getFullYear() - 1; year >= minYear; year--) {
    result.push({
      title: year,
      items: months,
    });
  }
  return result;
};

const data = [
  {
    title: 'Category',
    label: 'category',
    width: {
      simple: 160,
      wide: 255,
    },
    countTags: {
      simple: 1,
      wide: 2,
    },
    items: ['Video Slot', 'Instant Game', 'Table Game'],
  },
  {
    title: 'Release Date',
    label: 'releaseDate',
    widthDropdown: 262,
    width: {
      simple: 220,
      wide: 335,
    },
    countTags: {
      simple: 2,
      wide: 4,
    },
    items: fillDate(2017),
  },
  {
    title: 'Languages',
    label: 'language',
    width: {
      simple: 180,
      wide: 283,
    },
    countTags: {
      simple: 1,
      wide: 2,
    },
    search: true,
    items: Object.keys(Languages),
  },
  {
    title: 'RTP',
    label: 'rtp',
    width: {
      simple: 185,
      wide: 273,
    },
    countTags: {
      simple: 1,
      wide: 2,
    },
    items: ['≥97%', '96% - 96.99%', '95% - 95.99%', '94% - 94.99%', '≤93%'],
  },
  {
    title: 'Volatility',
    label: 'volatility',
    width: {
      simple: 155,
      wide: 215,
    },
    countTags: {
      simple: 1,
      wide: 2,
    },
    items: [
      'high high',
      'high med',
      'high low',
      'med high',
      'med med',
      'med low',
      'low high',
      'low med',
      'low low',
    ],
  },
  {
    title: 'Jurisdiction',
    label: 'jurisdiction',
    width: {
      simple: 160,
      wide: 255,
    },
    countTags: {
      simple: 1,
      wide: 2,
    },
    items: ['Bulgaria', 'Croatia', 'Italy', 'Lithuania', 'Malta', 'Romania'],
  },
  {
    title: 'Features',
    label: 'feature',
    search: true,
    width: {
      simple: 220,
      wide: 335,
    },
    countTags: {
      simple: 1,
      wide: 2,
    },
    items: [
      'Auto Hold',
      'Bets History',
      'Betting Gameplay',
      'Betways Mechanic',
      'Bonus Achievements',
      'Bonus Buy Feature',
      'Bonus Field',
      'Bonus Game',
      'Bonus Symbols',
      'Bonus Zone',
      'Bonuses',
      'Booster',
      'Catch Area',
      'Character Selection',
      'Character Symbols',
      'Characters’ Abilities',
      'Collector Feature',
      'Complexity Level',
      'Dice Game',
      'Double Chance',
      'Double Wild Symbol',
      'Drops and Wins',
      'Expanding Reels Feature',
      'Expanding Symbols',
      'Expanding Wilds',
      'Extra Free Spins',
      'Extra Symbols',
      'Free Spins',
      'Free Spins Options',
      'Full Moon Feature',
      'Grand Reel',
      'Group Wins',
      'Growing Reels',
      'Hands',
      'In-Game Chat',
      'Jackpot Game',
      'Jackpot Symbols',
      'Jackpots',
      'Lucky Trees',
      'Magician Symbol',
      'Manual Mode',
      'Money Symbols',
      'Multiple Bets',
      'Multipliers',
      'Mystery Symbols',
      'Nudging Wild',
      'Pattern',
      'Progress Bar',
      'Progressive Jackpots',
      'Random Event',
      'Random Wilds',
      'Reels Synchronisation',
      'Replace Symbols',
      'Replace Wild',
      'Respin Feature',
      'Risk Level',
      'Shifting Wild Symbols',
      'Side Bets',
      'Skin Shop',
      'Special Wilds',
      'Stack Symbols',
      'Stacked Wild',
      'Sticky Wilds',
      'Symbol Transformation',
      'Third-Person Shooter',
      'Top 100 Leaderboard',
      'Two Bets',
      'Ultra Spin Bonus',
      'Unique Formula',
      'Unlimited Treasures Feature',
      'Walking Wild',
      'Wheel of Fortune',
      'Wild Attack Feature',
      'Wild Multiplier',
    ],
  },
  {
    title: 'Tags',
    label: 'tag',
    search: true,
    width: {
      simple: 180,
      wide: 283,
    },
    countTags: {
      simple: 1,
      wide: 2,
    },
    items: [
      '3D',
      'Adventures',
      'Animals',
      'Arabic',
      'Asia',
      'Auction',
      'Aztec',
      'Baccarat',
      'Bingo',
      'Blackjack',
      'Book',
      'Buying Feature',
      'Car',
      'Cards',
      'Carnival',
      'Cartoonish',
      'Chests',
      'Circus/Cabaret',
      'CIS',
      'Classic',
      'Coins',
      'Crash Games',
      'Cross Channel',
      'Dice',
      'Egypt',
      'Farm',
      'Fight',
      'Fire',
      'Fishing',
      'Food',
      'Football',
      'Fruits',
      'Gangsters/Mafia',
      'Girls',
      'Gladiators',
      'Gods',
      'Halloween',
      'Holiday themed',
      'Innovations',
      'Irish themed',
      'Jackpots',
      'Jewellery',
      'Lottery',
      'Magical',
      'Match 3',
      'Mine Sweepers',
      'Money',
      'Mysterious',
      'Mythical',
      'Neon',
      'Nordic',
      'Other',
      'Pirates',
      'Poker',
      'Roulette',
      'Scratch',
      'Sea',
      'Sevens',
      'Spooky',
      'Sport',
      'Sports/Betting',
      'Summer',
      'Sweets',
      'Tetris',
      'Treasures',
      'Vegas',
      'Vikings',
      'Wheels',
      'Wild West',
      'Witches',
    ],
  },
  {
    title: 'Marker',
    label: 'marker',
    width: {
      simple: 185,
      wide: 273,
    },
    countTags: {
      simple: 1,
      wide: 2,
    },
    items: [
      'New Game',
      'EVOPLAY’s Choice',
      'TOP Asia',
      'TOP Europe',
      'TOP Asia & Europe',
      'Unique Graphics',
      'Special Terms',
    ],
  },
  {
    title: 'Promo tools',
    label: 'promo',
    width: {
      simple: 155,
      wide: 215,
    },
    countTags: {
      simple: 1,
      wide: 2,
    },
    items: ['Quests', 'Tournaments', 'Prize Drops', 'Free Spins/Rounds'],
  },
];

export const shortTitleMap = {
  'Chinese (Simple)': 'Chinese (S)',
  'Chinese (Traditional)': 'Chinese (T)',
  'Portuguese (Brazil)': 'Portuguese (B)',
  'EVOPLAY’s Choice': 'EVOPLAY’s',
  'Free Spins/Rounds': 'Free (S/R)',
  'Russian (russia is a terrorist state)': 'Russian (terrorist)',
};

export const iconTitleMap = {
  'TOP Asia': '🔥 TOP Asia',
  'TOP Europe': '🔥 TOP Europe',
  'TOP Asia & Europe': '🔥 TOP Asia & Europe',
};

export const prepareFiltersByQuery = (filters) =>
  Object.keys(filters).reduce(
    (acc, key) => ({
      ...acc,
      [key]:
        key === 'language'
          ? filters[key].map((el) => Languages[el]).join(',')
          : key === 'rtp'
            ? filters[key]
                .map((el) => {
                  switch (el) {
                    case '≥97%':
                      return '97,100';
                    case '96% - 96.99%':
                      return '96,96.99';
                    case '95% - 95.99%':
                      return '95,95.99';
                    case '94% - 94.99%':
                      return '94,94.99';
                    case '≤93%':
                      return '0,93';
                    default:
                      return '';
                  }
                })
                .join(',')
            : filters[key].join(','),
    }),
    {},
  );

export default data;
