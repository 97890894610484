import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';

import { allGamesSelector, clearSelectedItems } from 'store/slices/allGames';

import Content from 'components/pages/AllGames/Content/Content';
import Download from 'components/pages/AllGames/Download/Download';
import Filters from 'components/pages/AllGames/Filters/Filters';
import Search from 'components/pages/AllGames/Search/Search';
import SortBy from 'components/pages/AllGames/SortBy/SortBy';
import ViewType from 'components/pages/AllGames/ViewType/ViewType';

import styles from './AllGames.module.scss';

const AllGames = () => {
  const dispatch = useDispatch();
  const isVisible = useSelector(allGamesSelector.getVisible);

  useEffect(() => {
    return () => dispatch(clearSelectedItems());
  }, []);

  return (
    <>
      <div className={styles.wrapperHeader}>
        <div className={styles.wrapperFilters}>
          <div
            className={classNames(styles.filters, {
              [styles.filtersHide]: !isVisible,
            })}
          >
            <Filters />
          </div>
        </div>
        <div className={styles.wrapperActions}>
          <div className={styles.content}>
            <Download />
            <div className={styles.wrapperSort}>
              <Search />
              <SortBy />
              <ViewType />
            </div>
          </div>
        </div>
      </div>
      <Content />
    </>
  );
};

export default AllGames;
