import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { allGamesSelector } from 'store/slices/allGames';

import { findPathPages } from 'utils/pages';

import Type from 'components/GameItem/Type/Type';
import Checkbox from 'components/UI/Checkbox/Checkbox';

import { PAGE_PATH } from 'components/config/pages';

import ComingSoonImg from 'images/Roadmap/ComingSoon.jpg';

import styles from 'components/GameItem/Card/Card.module.scss';

const propTypes = {
  item: PropTypes.shape({
    icon: PropTypes.string,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    gameCode: PropTypes.string.isRequired,
    marker: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func,
  checkbox: PropTypes.bool,
  style: PropTypes.string,
};

const Card = ({
  item: { icon, name, gameCode, type, marker },
  style = null,
  checkbox = false,
  onClick = () => {},
}) => {
  const checkedItems = useSelector(allGamesSelector.getSelectedItems);

  return (
    <Link
      className={classNames(styles.wrapper, style)}
      to={findPathPages(PAGE_PATH.GAME).replace(':gameCode', gameCode)}
    >
      <div className={styles.imgWrapper}>
        <LazyLoadImage src={icon || ComingSoonImg} alt={name} />
        {marker && <Type type={marker} />}
        {checkbox && (
          <Checkbox
            isOpacity
            checked={checkedItems.includes(gameCode)}
            className={styles.checkbox}
            onClick={(event) => {
              event.preventDefault();
              onClick(gameCode);
            }}
          />
        )}
      </div>
      <div className={styles.info}>
        <div className={styles.category}>{type}</div>
        <div className={styles.title}>{name}</div>
      </div>
    </Link>
  );
};

Card.propTypes = propTypes;
export default Card;
