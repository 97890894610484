import React, { useMemo } from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { isImgMedia } from 'utils/img';
import { findPathPages } from 'utils/pages';
import { VIEW_MODE } from 'utils/viewMode';

import GameId from 'components/GameId/GameId';
import Button from 'components/UI/Button/Button';
import Tags from 'components/pages/Promotions/Tags/Tags';

import { PAGE_PATH } from 'components/config/pages';

import styles from 'components/Slider/ActiveSlide/ActiveSlide.module.scss';

const propTypes = {
  item: PropTypes.shape({
    sliderSmall: PropTypes.string.isRequired,
    sliderLarge: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    gameCode: PropTypes.string.isRequired,
    type: PropTypes.string,
    id: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        region: PropTypes.string,
      }),
    ),
    logo: PropTypes.string,
    name: PropTypes.string,
    releaseDate: PropTypes.string,
    promoTags: PropTypes.arrayOf(PropTypes.string),
    link: PropTypes.string,
  }).isRequired,
  handleClick: PropTypes.func.isRequired,
  viewMode: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['vertical', 'horizontal']).isRequired,
  isActive: PropTypes.bool.isRequired,
};

const ActiveSlide = ({ item, handleClick, viewMode, type, isActive }) => {
  const imgMode = viewMode === VIEW_MODE.SIMPLE ? 'sliderSmall' : 'sliderLarge';
  const isImgBackground = useMemo(() => isImgMedia(item[imgMode]), [item]);

  return (
    <div
      className={classNames(styles.wrapper, styles[type], {
        [styles.isActive]: isActive,
      })}
      onClick={handleClick}
    >
      {isImgBackground && (
        <img
          src={item[imgMode]}
          className={styles.background}
          alt="background"
        />
      )}
      {!isImgBackground && (
        <ReactPlayer
          url={item[imgMode]}
          playing={true}
          controls={false}
          loop={true}
          muted={true}
          className={styles.background}
        />
      )}
      <div className={styles.gradient}>
        {item.logo && (
          <img src={item.logo} alt="active slide" className={styles.logo} />
        )}
        <div className={styles.categoryWrapper}>
          {item.type && (
            <span className={styles.category}>
              <span>{item.type}</span>
            </span>
          )}
          {item.promoTags && <Tags tags={item.promoTags} />}
          {type === 'vertical' && item.id.length > 0 && <GameId id={item.id} />}
        </div>
        {type === 'horizontal' && item.name && (
          <div
            dangerouslySetInnerHTML={{ __html: item.name }}
            className={styles.title}
          />
        )}
        {item.releaseDate && (
          <div className={styles.date}>{item.releaseDate}</div>
        )}
        <div
          dangerouslySetInnerHTML={{ __html: item.description }}
          className={styles.description}
        />
        <Button
          component={Link}
          to={
            item.link
              ? item.link
              : findPathPages(PAGE_PATH.GAME).replace(
                  ':gameCode',
                  item.gameCode,
                )
          }
        >
          Learn more
        </Button>
      </div>
    </div>
  );
};

ActiveSlide.propTypes = propTypes;

export default ActiveSlide;
