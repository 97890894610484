import React from 'react';

import PropTypes from 'prop-types';

import { formatViewDate } from 'utils/date';

import ComingSoon from 'components/ComingSoon/ComingSoon';
import GameId from 'components/GameId/GameId';
import Type from 'components/GameItem/Type/Type';
import ItemLayout from 'components/Layouts/Roadmap/ItemLayout';

import ComingSoonImg from 'images/Roadmap/ComingSoon.jpg';

import styles from 'components/pages/Roadmap/Item/Item.module.scss';

const propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  gameCode: PropTypes.string,
  id: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      region: PropTypes.string,
    }),
  ),
  icon: PropTypes.string,
  marker: PropTypes.string,
  rtp: PropTypes.arrayOf(PropTypes.number),
  volatility: PropTypes.string,
  paylines: PropTypes.string,
  maxMultiplier: PropTypes.number,
  releaseDate: PropTypes.string,
};

const Item = ({
  id,
  gameCode,
  icon,
  name,
  marker,
  rtp,
  volatility,
  paylines,
  maxMultiplier,
  releaseDate,
  type,
}) => (
  <ItemLayout gameCode={gameCode}>
    <div className={styles.imgWrapper}>
      {marker && <Type type={marker} />}
      <img src={icon || ComingSoonImg} alt={name} loading="lazy" />
    </div>
    <div className={styles.content}>
      <div className={styles.header}>
        <div className={styles.title}>
          <span>{name}</span>
        </div>
        {releaseDate && (
          <div className={styles.date}>
            <span>{formatViewDate(new Date(releaseDate))}</span>
          </div>
        )}
        <div className={styles.categoryWrapper}>
          <span className={styles.category}>
            <span>{type}</span>
          </span>
          {id.length > 0 && <GameId id={id} color="grey" />}
        </div>
      </div>
      <div className={styles.info}>
        <div className={styles.row}>
          <span>RTP, %:</span>
          <span>{rtp.length ? `${rtp.join('%, ')}%` : <ComingSoon />}</span>
        </div>
        <div className={styles.row}>
          <span>Volatility:</span>
          <span>{volatility ? volatility : <ComingSoon />}</span>
        </div>
        <div className={styles.row}>
          <span>Paylines:</span>
          <span>{paylines ? paylines : <ComingSoon />}</span>
        </div>
        <div className={styles.row}>
          <span>Max Multiplier:</span>
          <span>{maxMultiplier ? maxMultiplier : <ComingSoon />}</span>
        </div>
      </div>
    </div>
  </ItemLayout>
);

Item.propTypes = propTypes;
export default Item;
