import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import classNames from 'classnames';

import { useGetGamesListQuery } from 'store/api/gameService/recipe.api';
import { allGamesSelector, selectItem } from 'store/slices/allGames';

import { searchGames } from 'utils/games';

import Card from 'components/GameItem/Card/Card';
import FullInfo from 'components/GameItem/FullInfo/FullInfo';
import Loader from 'components/Loader/Loader';

import { ReactComponent as EmptyFiltersIcon } from 'images/Icons/EmptyFilters.svg';

import styles from './Content.module.scss';

const Content = () => {
  const dispatch = useDispatch();
  const viewType = useSelector(allGamesSelector.getViewType);
  const search = useSelector(allGamesSelector.getSearch);
  const sortBy = useSelector(allGamesSelector.getSortBy);
  const filters = useSelector(allGamesSelector.getFilters);

  const { provider } = useParams();

  const { data } = useGetGamesListQuery({
    sort: sortBy,
    filters,
    provider,
  });

  const GameComponent = viewType === 'row' ? FullInfo : Card;

  const handleClickCheckbox = useCallback(
    (itemId) => dispatch(selectItem(itemId)),
    [],
  );

  if (!data || !data.games) {
    return <Loader />;
  }

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.card]: viewType === 'column' && data.games.length > 0,
        [styles.content]: !data.games.length,
      })}
    >
      {!data.games.length && (
        <div className={styles.empty}>
          <EmptyFiltersIcon />
          <div>
            <div className={styles.title}>
              We couldn&apos;t find any results for the chosen filters.
            </div>
            <div>Please try changing your filter parameters.</div>
          </div>
        </div>
      )}
      {data.games.length > 0 &&
        searchGames(data.games, search).map((item) => (
          <GameComponent
            key={item.gameCode}
            item={item}
            checkbox
            onClick={handleClickCheckbox}
          />
        ))}
    </div>
  );
};

export default Content;
