import React, { useCallback } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import { allGamesSelector } from 'store/slices/allGames';
import { viewModeSelector } from 'store/slices/viewMode';

import { formatViewDate } from 'utils/date';
import { findPathPages } from 'utils/pages';
import { VIEW_MODE } from 'utils/viewMode';

import GameId from 'components/GameId/GameId';
import Type from 'components/GameItem/Type/Type';
import Button from 'components/UI/Button/Button';
import Checkbox from 'components/UI/Checkbox/Checkbox';
import Popover from 'components/UI/Popover/Popover';

import { PAGE_PATH } from 'components/config/pages';

import ComingSoonImg from 'images/Roadmap/ComingSoon.jpg';

import styles from './FullInfo.module.scss';

const propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    gameCode: PropTypes.string.isRequired,
    id: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        region: PropTypes.string.isRequired,
      }),
    ).isRequired,
    type: PropTypes.string.isRequired,
    releaseDate: PropTypes.string.isRequired,
    icon: PropTypes.string,
    rtp: PropTypes.arrayOf(PropTypes.number),
    rtpOptions: PropTypes.arrayOf(PropTypes.number),
    volatility: PropTypes.string,
    paylines: PropTypes.string,
    minBet: PropTypes.number,
    maxBet: PropTypes.number,
    maxMultiplier: PropTypes.number,
    maxWin: PropTypes.number,
    promoTools: PropTypes.arrayOf(PropTypes.string),
    features: PropTypes.arrayOf(PropTypes.string),
    tags: PropTypes.arrayOf(PropTypes.string),
    marker: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

const FullInfo = ({ item, onClick }) => {
  const checkedItems = useSelector(allGamesSelector.getSelectedItems);
  const viewMode = useSelector(viewModeSelector.getViewMode);
  const isSimpleMode = viewMode === VIEW_MODE.SIMPLE;

  const handleCheck = useCallback((gameCode) => onClick(gameCode), []);
  const defaultText = <span className={styles.empty}>&mdash;</span>;

  return (
    <div className={styles.wrapper}>
      <div className={styles.img}>
        <LazyLoadImage src={item.icon || ComingSoonImg} alt={item.name} />
        {item.marker && <Type type={item.marker} />}
        <Checkbox
          isOpacity
          checked={checkedItems.includes(item.gameCode)}
          className={styles.checkbox}
          onClick={() => handleCheck(item.gameCode)}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.gameTitle}>
          <div className={styles.title}>{item.name}</div>
          <div className={styles.categoryWrapper}>
            <span className={styles.category}>
              <span>{item.type}</span>
            </span>
            {item.id.length > 0 && <GameId id={item.id} />}
          </div>
          <Button
            className={styles.moreBtn}
            component={Link}
            to={findPathPages(PAGE_PATH.GAME).replace(
              ':gameCode',
              item.gameCode,
            )}
          >
            More info
          </Button>
        </div>
        <div className={styles.gameInfo}>
          <div className={styles.column}>
            <div className={styles.row}>
              <span>Release date:</span>
              <span>{formatViewDate(new Date(item.releaseDate))}</span>
            </div>
            <div className={styles.row}>
              <span>RTP, %:</span>
              <span>
                {item.rtp.length ? `${item.rtp.join('%, ')}%` : defaultText}
              </span>
            </div>
            <div className={styles.row}>
              <span>RTP Options:</span>
              <span>
                {item.rtpOptions.length
                  ? `${item.rtpOptions.join('%, ')}%`
                  : defaultText}
              </span>
            </div>
            <div className={styles.row}>
              <span>Volatility:</span>
              <span>{item.volatility ? item.volatility : defaultText}</span>
            </div>
            <div className={styles.row}>
              <span>Paylines:</span>
              <span>{item.paylines ? item.paylines : defaultText}</span>
            </div>
            {isSimpleMode && (
              <div className={styles.row}>
                <span>Min Bet, €:</span>
                <span>{item.minBet ? `€ ${item.minBet}` : defaultText}</span>
              </div>
            )}
          </div>
          <div className={styles.column}>
            {!isSimpleMode && (
              <div className={styles.row}>
                <span>Min Bet, €:</span>
                <span>{item.minBet ? `€ ${item.minBet}` : defaultText}</span>
              </div>
            )}
            <div className={styles.row}>
              <span>Max Bet, €:</span>
              <span>{item.maxBet ? `€ ${item.maxBet}` : defaultText}</span>
            </div>
            <div className={styles.row}>
              <span>Max Multiplier:</span>
              <span>
                {item.maxMultiplier ? item.maxMultiplier : defaultText}
              </span>
            </div>
            <div className={styles.row}>
              <span>Max Win, €:</span>
              <span>{item.maxWin ? `€ ${item.maxWin}` : defaultText}</span>
            </div>
            {isSimpleMode && (
              <div className={styles.row}>
                <span>Promo tools:</span>
                <span className={styles.tag}>
                  {item.promoTools.length ? (
                    <>
                      {item.promoTools.slice(0, 2).map((value, index) => (
                        <span key={index}>{value}</span>
                      ))}
                      {item.promoTools.length > 2 && (
                        <Popover data={item.promoTools} countMore={2} />
                      )}
                    </>
                  ) : (
                    defaultText
                  )}
                </span>
              </div>
            )}
            {isSimpleMode && (
              <div className={styles.row}>
                <span>Game Features:</span>
                <span className={styles.tag}>
                  {item.features.length ? (
                    <>
                      <span>{item.features[0]}</span>
                      {item.features.length > 1 && (
                        <Popover data={item.features} countMore={1} />
                      )}
                    </>
                  ) : (
                    defaultText
                  )}
                </span>
              </div>
            )}
            {isSimpleMode && (
              <div className={styles.row}>
                <span>Tags:</span>
                <span className={styles.tag}>
                  {item.tags.length ? (
                    <>
                      {item.tags.slice(0, 2).map((value, index) => (
                        <span key={index}>{value}</span>
                      ))}
                      {item.tags.length > 2 && (
                        <Popover data={item.tags} countMore={2} />
                      )}
                    </>
                  ) : (
                    defaultText
                  )}
                </span>
              </div>
            )}
          </div>
          {!isSimpleMode && (
            <div className={styles.column}>
              <div className={styles.row}>
                <span>Promo tools:</span>
                <span className={styles.tag}>
                  {item.promoTools.length
                    ? item.promoTools.map((value, index) => (
                        <span key={index}>{value}</span>
                      ))
                    : defaultText}
                </span>
              </div>
              <div className={styles.row}>
                <span>Game Features:</span>
                <span className={styles.tag}>
                  {item.features.length ? (
                    <>
                      {item.features.slice(0, 9).map((value, index) => (
                        <span key={index}>{value}</span>
                      ))}
                      {item.features.length > 9 && (
                        <Popover data={item.features} countMore={9} />
                      )}
                    </>
                  ) : (
                    defaultText
                  )}
                </span>
              </div>
              <div className={styles.row}>
                <span>Tags:</span>
                <span className={styles.tag}>
                  {item.tags.length ? (
                    <>
                      {item.tags.slice(0, 14).map((value, index) => (
                        <span key={index}>{value}</span>
                      ))}
                      {item.tags.length > 14 && (
                        <Popover data={item.tags} countMore={14} />
                      )}
                    </>
                  ) : (
                    defaultText
                  )}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

FullInfo.propTypes = propTypes;
export default FullInfo;
