import React from 'react';

import { useGetRecommendedQuery } from 'store/api/gameService/recipe.api';

import { findPathPages } from 'utils/pages';

import HomeComponentsLayout from 'components/Layouts/HomeComponents/HomeComponentsLayout';
import Slider from 'components/Slider/Slider';

import { PAGE_PATH } from 'components/config/pages';

const RecommendedGames = () => {
  const { data } = useGetRecommendedQuery();

  if (!data || !Array.isArray(data) || !data.length) {
    return null;
  }

  return (
    <HomeComponentsLayout
      title="Recommended Games"
      linkPath={findPathPages(PAGE_PATH.ALL_GAMES).replace(
        ':provider',
        'evoplay',
      )}
      linkTitle="All Evoplay Games"
    >
      <Slider
        data={data.reduce(
          (acc, value) => [
            ...acc,
            {
              gameCode: value.gameCode,
              sliderSmall: value.sliderSmall,
              sliderLarge: value.sliderLarge,
              icon: value.icon,
              logo: value.logo,
              description: value.description,
              type: value.type,
              id: value.id,
              name: value.name,
            },
          ],
          [],
        )}
        type="vertical"
      />
    </HomeComponentsLayout>
  );
};

export default RecommendedGames;
