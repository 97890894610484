import { api } from 'store/api/tournamentService/api';

import { config } from 'config/tournaments';

const tournamentConfig = config[process.env.REACT_APP_VERSION];

export const recipeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTournaments: build.query({
      query: (params) => ({
        url: '/tournament-service/api/v3/promo-events/tournaments',
        params: {
          ...params,
          ...tournamentConfig,
        },
      }),
    }),
    getTournamentsWidget: build.query({
      query: (params) => ({
        url: `/tournament-service/api/v3/promo-events/widget`,
        params: {
          ...params,
          ...tournamentConfig,
        },
      }),
    }),
    getTournamentLeaderBoard: build.query({
      query: ({ event_id, ...params }) => ({
        url: `/tournament-service/api/v3/promo-events/tournaments/${event_id}/leaderboard`,
        params: {
          ...params,
          ...tournamentConfig,
        },
      }),
    }),
    getTournamentPrizeDistribution: build.query({
      query: ({ event_id }) => ({
        url: `/tournament-service/api/v3/promo-events/tournaments/${event_id}/prize-distribution`,
        params: {
          player_currency: tournamentConfig.player_currency,
          lang: tournamentConfig.lang,
          locale: tournamentConfig.locale,
        },
      }),
    }),
    getTournamentRules: build.query({
      query: ({ event_id }) => ({
        url: `/tournament-service/api/v3/promo-events/tournaments/${event_id}/rules`,
        params: {
          lang: tournamentConfig.lang,
          locale: tournamentConfig.locale,
        },
      }),
    }),
    getTournamentResets: build.query({
      query: ({ event_id, ...params }) => ({
        url: `/tournament-service/api/v2/tournaments/resets/${event_id}`,
        params: {
          operator_code: tournamentConfig.operator_code,
          ...params,
        },
      }),
      providesTags: () => [{ type: 'TournamentResets' }],
    }),
    resetTournamentScore: build.mutation({
      query: ({ event_id, ...params }) => ({
        url: `/tournament-service/api/v2/tournaments/resets/${event_id}`,
        method: 'POST',
        params: {
          operator_code: tournamentConfig.operator_code,
          ...params,
        },
      }),
      invalidatesTags: () => [{ type: 'TournamentResets' }],
    }),
  }),
});

export const {
  useGetTournamentsQuery,
  useGetTournamentsWidgetQuery,
  useGetTournamentLeaderBoardQuery,
  useGetTournamentPrizeDistributionQuery,
  useGetTournamentRulesQuery,
  useGetTournamentResetsQuery,
  useResetTournamentScoreMutation,
} = recipeApi;
