import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { userSelector } from 'store/slices/user';

import { doLogout } from 'services/UserService';

import Loader from 'components/Loader/Loader';
import Button from 'components/UI/Button/Button';

import { mapUserInfo } from 'components/config/profile';
import { emails } from 'config/organization';

import { ReactComponent as InfoIcon } from 'images/Icons/Info.svg';
import { ReactComponent as LogoutIcon } from 'images/Icons/Logout.svg';

import styles from './Account.module.scss';

const Account = () => {
  const [data, setData] = useState({});
  const userInfo = useSelector(userSelector.getInfo);

  useEffect(() => {
    if (userInfo && Object.keys(userInfo).length) {
      setData(mapUserInfo(userInfo));
    }
  }, [userInfo]);

  if (!data || !Object.keys(data).length) {
    return <Loader />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <span>Personal Account</span>
        <Button
          onClick={doLogout}
          startIcon={<LogoutIcon />}
          variant="icon"
          className={styles.btn}
        >
          Log Out
        </Button>
      </div>
      <div className={styles.content}>
        {Object.keys(data).map((item) => (
          <div className={styles.option} key={item}>
            <div className={styles.label}>{item}</div>
            <div className={styles.input}>{data[item]}</div>
          </div>
        ))}
        <div className={styles.info}>
          <InfoIcon />
          <div>
            Your information can’t be changed here. If you would like to change
            it, please contact your KAM or email us at&nbsp;
            <a href={`mailto:${emails.support}`}>{emails.support}</a>.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
