import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './Loader.module.scss';

const propTypes = {
  type: PropTypes.oneOf(['content', 'process']),
};

const Loader = ({ type = null }) => (
  <div
    className={classNames(styles.wrapper, {
      [styles.content]: type === 'content',
      [styles.process]: type === 'process',
    })}
    data-testid="loader-root"
  >
    <div className={styles.loader}>Loader...</div>
  </div>
);

Loader.propTypes = propTypes;
export default Loader;
