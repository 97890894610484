import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { baseQueryWithRauth } from 'store/api/queryWithReAuth';

import { getToken } from 'services/UserService';

import { config } from 'config/api/tournament-service';

const baseQuery = fetchBaseQuery({
  baseUrl: config[process.env.REACT_APP_VERSION],
  prepareHeaders: (headers) => {
    const accessToken = getToken();
    if (accessToken) {
      headers.set('authorization', `Bearer ${accessToken}`);
    }

    headers.set('Content-Type', 'application/json');
    return headers;
  },
});

export const api = createApi({
  reducerPath: 'tournamentService',
  tagTypes: ['TournamentResets'],
  baseQuery: (...props) => baseQueryWithRauth(...props, baseQuery),
  endpoints: () => ({}),
});
