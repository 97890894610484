import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';

import { downloadProcessSelector } from 'store/slices/downloadProcess';

import Loader from 'components/Loader/Loader';

import styles from './DownloadProcess.module.scss';

const DownloadProcess = () => {
  const items = useSelector(downloadProcessSelector.getItems);
  const [expand, setExpand] = useState(true);
  const handleClick = useCallback(
    () => setExpand((prevState) => !prevState),
    [],
  );

  if (!items || !Object.keys(items).length) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header} onClick={handleClick}>
        <div>Loading</div>
        <Loader type="process" />
        <div className={styles.arrowWrapper}>
          <div
            className={classNames(styles.arrow, {
              [styles.arrowUp]: !expand,
              [styles.arrowDown]: expand,
            })}
          ></div>
        </div>
      </div>
      {expand && (
        <div className={styles.content}>
          {Object.keys(items).map((item) => (
            <div className={styles.item} key={item}>
              <div
                className={styles.fileName}
                dangerouslySetInnerHTML={{ __html: item }}
              />
              <div className={styles.progressBar}>
                <div style={{ width: `${items[item].percent}%` }} />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DownloadProcess;
