import React from 'react';
import { useSelector } from 'react-redux';

import { useGetLatestReleasesQuery } from 'store/api/gameService/recipe.api';
import { viewModeSelector } from 'store/slices/viewMode';

import { findPathPages } from 'utils/pages';
import { VIEW_MODE } from 'utils/viewMode';

import Card from 'components/GameItem/Card/Card';
import AnimationLayout from 'components/Layouts/Animation/AnimationLayout';
import HomeComponentsLayout from 'components/Layouts/HomeComponents/HomeComponentsLayout';

import { PAGE_PATH } from 'components/config/pages';

import styles from './LatestReleases.module.scss';

const LatestReleases = () => {
  const viewMode = useSelector(viewModeSelector.getViewMode);
  const countSize = viewMode === VIEW_MODE.SIMPLE ? 4 : 6;
  const { data } = useGetLatestReleasesQuery({ count: 6 });

  if (!data || !Array.isArray(data) || !data.length) {
    return null;
  }

  return (
    <HomeComponentsLayout
      linkPath={findPathPages(PAGE_PATH.ALL_GAMES).replace(
        ':provider',
        'evoplay',
      )}
      title="Latest Releases"
      linkTitle="All Evoplay Games"
      styleWrapper={styles.wrapper}
      styleHeader={styles.header}
    >
      <AnimationLayout animation="fadeInLeft">
        <div className={styles.content}>
          {data.slice(0, countSize).map((item) => (
            <Card key={item.gameCode} item={item} />
          ))}
        </div>
      </AnimationLayout>
    </HomeComponentsLayout>
  );
};

export default LatestReleases;
