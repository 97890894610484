import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: {},
};

const downloadProcessSlice = createSlice({
  name: 'downloadProcess',
  initialState,
  reducers: {
    process: (state, action) => {
      const { name, percent } = action.payload;
      state.items[name] = {
        percent,
      };
    },
    finish: (state, action) => {
      if (state.items[action.payload]) {
        delete state.items[action.payload];
      }
    },
    clear: () => initialState,
  },
});

export const downloadProcessSelector = {
  getItems: (state) => state.downloadProcess.items,
};

export const { process, finish, clear } = downloadProcessSlice.actions;
export default downloadProcessSlice.reducer;
