import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import JSZip from 'jszip';

import { dateStringToDate } from 'utils/date';
import { fetchUrls, saveAsFile } from 'utils/download';

import { data as initialData } from 'components/config/certificate';
import { Sort } from 'components/config/sort';

export const download = createAsyncThunk(
  'certificate/download',
  async ({ urls, schema, zipFileName }, { rejectWithValue }) => {
    try {
      const { results, errors } = await fetchUrls(urls);
      if (results.length) {
        const zip = new JSZip();

        results.map(({ url, blob }) => {
          if (schema === 'certificates') {
            const urlSplit = url.split('/Certificates/');
            const folder = zip.folder(
              urlSplit[0].split('/Games/').pop().replace(/\+/g, ' '),
            );
            folder.file(urlSplit[1], blob);
          } else {
            zip.file(url.split('/').pop(), blob);
          }
        });

        const zipFile = await zip.generateAsync({ type: 'blob' });
        saveAsFile(zipFile, `${zipFileName}.zip`);
      }
      if (errors.length) {
        return rejectWithValue(errors);
      }
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

const sortData = (data, sortBy) => {
  if (Sort[0] === sortBy) {
    return data.sort((a, b) => {
      const aDate = dateStringToDate(a['Marketing Release']);
      const bDate = dateStringToDate(b['Marketing Release']);
      return bDate - aDate;
    });
  }
  if (Sort[1] === sortBy) {
    return data.sort((a, b) => {
      const aDate = dateStringToDate(a['Marketing Release']);
      const bDate = dateStringToDate(b['Marketing Release']);
      return aDate - bDate;
    });
  }
  if (Sort[2] === sortBy) {
    return data.sort((a, b) => {
      const nameA = a.Game.toUpperCase();
      const nameB = b.Game.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }
  if (Sort[3] === sortBy) {
    return data.sort((a, b) => {
      const nameA = a.Game.toUpperCase();
      const nameB = b.Game.toUpperCase();
      if (nameA < nameB) {
        return 1;
      }
      if (nameA > nameB) {
        return -1;
      }
      return 0;
    });
  }

  return data;
};

const initialState = {
  loading: false,
  error: null,
  data: initialData,
  sortBy: Sort[2],
  filter: '',
};

export const certificateSlice = createSlice({
  name: 'certificate',
  initialState,
  reducers: {
    sortBy: (state, action) => {
      state.sortBy = action.payload;
      state.data = sortData(state.data, state.sortBy);
    },
    filter: (state, action) => {
      state.filter = action.payload;
      const findName = action.payload.toLowerCase().trim();
      state.data = sortData(
        initialData.filter(
          (el) => el.Game.toLowerCase().indexOf(findName) !== -1,
        ),
        state.sortBy,
      );
    },
  },
  extraReducers: (builder) => {
    const { pending, fulfilled, rejected } = download;
    builder
      .addCase(pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(rejected, (state, action) => {
        state.loading = false;
        let errorText = action.payload;
        if (Array.isArray(action.payload)) {
          errorText = '<b>Error fetch urls:</b><br/>';
          action.payload.map(({ url }) => (errorText += url + '<br/>'));
        }
        state.error = errorText;
      });
  },
});

export const certificateSelector = {
  getLoading: (state) => state.certificate.loading,
  getError: (state) => state.certificate.error,
  getData: (state) => state.certificate.data,
  getSort: (state) => state.certificate.sortBy,
  getFilter: (state) => state.certificate.filter,
};
export const { sortBy, filter } = certificateSlice.actions;
export default certificateSlice.reducer;
